import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.sidebarHidden = true
    this.sidebarElement = document.getElementById("sidebar")
  }

  toggle(event) {
    event.preventDefault()
    event.stopPropagation()
    this.sidebarHidden = !this.sidebarHidden
    this.sidebarElement.classList.toggle("collapsed", this.sidebarHidden)
    this.element.classList.toggle("sidebar-shown", !this.sidebarHidden)
  }
}
