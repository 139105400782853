import { Controller } from "@hotwired/stimulus"
import { loadScript } from '@paypal/paypal-js';

export default class extends Controller {
  static values = {
		clientData: Object
	}
  static outlets = ["payment-form"]

  connect() {
    this._loadPayPal()
  }

  async confirm() {
		return false
	}

  update() {
		this._loadPayPal()
	}

  async validate() {
		return true
	}

  _buttonContainer(fundingSource) {
    return document.querySelector(
      "[data-payment-form-target='paymentMethodButton']" +
      "[data-processor-type='paypal']" +
      `[data-payment-method-type='${fundingSource}']`
    )
  }

  _createOrderOrBillingAgreement = async () => {
    await this.paymentFormOutlet.submit()
    return this.paymentFormOutlet.clientDataValue.id
  }

  async _loadPayPal() {
    try {
      const paypal = await loadScript(this._scriptLoadParams);
      this._togglePaymentMethods(paypal)
    } catch (error) {
      console.error("Failed to load the PayPal JS SDK script", error);
    }
  }

  _onApprove = () => {
    window.location.assign(this.paymentFormOutlet.returnUrlValue)
  }

  _onError = (error) => {
    this.paymentFormOutlet.showError(error.message)
  }

  _onCancel = () => {
    this.paymentFormOutlet.cancel()
  }

  get _scriptLoadParams() {
    return {
      clientId: this.clientDataValue.client_id.replace(/ /g, ''),
      commit: false,
      components: ["buttons", "funding-eligibility"],
      currency: this.paymentFormOutlet.currencyValue.toUpperCase(),
      intent: this.paymentFormOutlet.reusableValue ? "tokenize" : "capture",
      ...(!this.paymentFormOutlet.reusableValue || this.clientDataValue.merchant_initiated_enabled ? { merchantId: this.clientDataValue.account_id } : {}),
      vault: true
    }
  };

  _togglePaymentMethods(paypal) {
    paypal.getFundingSources().forEach(fundingSource => {
      const buttonContainer = this._buttonContainer(fundingSource)
      if (buttonContainer) {
        buttonContainer.innerHTML = ""
        const button = paypal.Buttons({
          fundingSource: fundingSource,
          displayOnly: [this.paymentFormOutlet.reusableValue ? "vaultable" : null],
          createBillingAgreement: this.paymentFormOutlet.reusableValue ? this._createOrderOrBillingAgreement : null,
          createOrder: this.paymentFormOutlet.reusableValue ? null : this._createOrderOrBillingAgreement,
          onApprove: this._onApprove,
          onError: this._onError,
          onCancel: this._onCancel
        })
        if (button.isEligible()) {
          this._togglePaymentMethod(fundingSource, true)
          button.render(buttonContainer)
        } else {
          this._togglePaymentMethod(fundingSource, false)
        }
      }
    })
  }

  _togglePaymentMethod(fundingSource, show) {
    const paymentMethodContainer = document.querySelector(
      "[data-payment-form-target='paymentMethod']" +
      "[data-processor-type='paypal']" +
      `[data-payment-method-type='${fundingSource}']`
    )
    if (paymentMethodContainer) {
      paymentMethodContainer.classList.toggle('hidden', !show)
    }
  }
}
