import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["dialog"]

	open() {
    this.dialogTarget.showModal()
    // This removes focus from any active elements so that the first option is not selected by default.
    document.activeElement.blur()
  }
}
