import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {inputName: String, showValue: {type: String, default: "1"}}

  connect() {
    this.boundToggle = this._toggle.bind(this);
    this._inputs.forEach(input => input.addEventListener("change", this.boundToggle))
    this._toggle()
  }

  disconnect() {
    this._inputs.forEach(input => input.removeEventListener("change", this.boundToggle))
  }

  _toggle() {
    this.element.classList.toggle("collapsed", !this._isShown)
  }

  get _currentValue() {
    // Try to find checkable inputs (radio and checkbox) first, then fall back to hidden input.
    let input = this._inputs.find(input => input.checked == true) || this._inputs[0]
    return input ? input.value : null
  }

  get _inputs() {
    return Array.from(document.querySelectorAll(`[name="${this.inputNameValue}"]`))
  }

  get _isShown() {
    return this._currentValue == this.showValueValue
  }
}
